import { useApolloClient } from "@apollo/client/main.cjs";
import {
  Autocomplete,
  Badge,
  Button,
  ChoiceList,
  DatePicker,
  Icon,
  LegacyCard,
  LegacyStack,
  Popover,
  Text,
  TextField,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { RiskMajor, SearchMinor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { ISmartrrOrderLineItem } from "@smartrr/shared/entities/OrderLineItem";
import { IOrganization, OrgUtils } from "@smartrr/shared/entities/Organization";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import {
  IPurchaseStateWithCustomerRelationship,
  PurchaseStateStatus,
} from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { queryShopifyCustomers } from "@smartrr/shared/shopifyGraphQL/customer";
import { nowUTCLuxon, toUTCDateLuxon, updateDateRecur } from "@smartrr/shared/utils/dateUtils";
import { getTotalDisplay } from "@smartrr/shared/utils/displayUtils";
import { hasShopifyGid, viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { getSymbolFromCurrency } from "@smartrr/shared/utils/formatMoney";
import {
  isCPSPrepaid,
  isCPSRecentlyMigratedPrepaid,
  isUpcomingOrderCPSPrepaid,
} from "@smartrr/shared/utils/isPrepaid";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import { capitalize, debounce, flatten, isNil, uniqBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

import { DatePickerContainer } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import { IdContainer } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import {
  CustomerAutocompleteProps,
  FailedBadgeContainer,
  IScheduledEventWithProjectedTotal,
  ItemsAutocompleteProps,
  NoUpcomingDateContainer,
  QuantityBadge,
  SubscriptionShopifyIdAutocompleteProps,
  getDeliveryAddress,
  getDeliveryMethodTitle,
  getFormattedWarningText,
  getNextDelivery,
  getSelectedDatesFromFilter,
  getSellingPlanOptions,
  getTimeFromDate,
  getVariantOptions,
  openSubscriptionDetails,
} from "..";
import { AutoCompleteWithTextField } from "../../../components/elements/AutocompleteWithTextField";
import { DateTime } from "luxon";

export * from "./paths";
export * from "./styles";
export * from "./table-utils";
export * from "./table-columns-defaults";

export function CustomerAutocomplete({
  selected,
  handleCustomerChange,
  textFieldProps,
}: CustomerAutocompleteProps): JSX.Element {
  const [autoCompleteCustomers, setAutoCompleteCustomers] = useState<ICustomerRelationshipShallow[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isAvailableOnShopify, setAvailableOnShopify] = useState(false);
  const [hasResults, setHasResults] = useState(true);
  const client = useApolloClient();

  const options = useMemo(() => {
    if (selected && selected.length) {
      return uniqBy(
        [
          ...autoCompleteCustomers
            .filter(el => el.email)
            .map(({ email }) => ({
              label: email,
              value: email,
            })),
          ...(selected ?? []).map(value => ({
            label: value,
            value,
          })),
        ],
        "value"
      );
    }
    return autoCompleteCustomers
      .filter(el => el.email)
      .map(({ email }) => ({
        label: email,
        value: email,
      }));
  }, [autoCompleteCustomers]);

  const checkResultsOnShopify = async (email: string, shopCustomersCount: number) => {
    if (!email || shopCustomersCount) {
      return;
    }

    setHasResults(false);

    const resp = await queryShopifyCustomers(client, `email:${email}`, 1);

    if (resp.type === "success" && resp.body.data.customers.edges.length) {
      setAvailableOnShopify(true);
    } else {
      setAvailableOnShopify(false);
    }
  };

  const onEmailChange = useCallback(
    debounce(async (email: string) => {
      setHasResults(true);

      if (!email) {
        setAutoCompleteCustomers([]);
        return;
      }

      setLoading(true);
      const res = await typedFrontendVendorApi.getReq("/customer-relationship", {
        query: {
          filterLike: {
            email,
          },
        },
      });

      if (res.type === "success") {
        const results = res.body.data;

        setAutoCompleteCustomers(results);

        checkResultsOnShopify(email, results.length);
      }

      setLoading(false);
    }, 250),
    []
  );

  return (
    <React.Fragment>
      <AutoCompleteWithTextField
        allowMultiple
        selected={selected}
        onSelect={values => handleCustomerChange(values)}
        onChange={onEmailChange}
        options={options}
        loading={isLoading}
        textFieldProps={textFieldProps}
        willLoadMoreResults
      />
      {!hasResults && (
        <React.Fragment>
          <Box mt={1} mb={+isAvailableOnShopify}>
            Seems like customer with provided email doesn{"'"}t exist in Smartrr{" "}
            {!isAvailableOnShopify && "and Shopify"}
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export function SubscriptionIdAutocomplete({
  selected,
  handleSubscriptionIdChange,
  textFieldProps,
}: SubscriptionShopifyIdAutocompleteProps): JSX.Element {
  const [autoCompleteSubscriptionIds, setAutoCompleteSubscriptionIds] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [hasResults, setHasResults] = useState(true);

  const options = useMemo(() => {
    if (selected && selected.length) {
      return uniqBy(
        [
          ...autoCompleteSubscriptionIds.filter(Boolean).map(shopifyId => ({
            label: hasShopifyGid(shopifyId) ? viewShopifyId(shopifyId) : shopifyId,
            value: hasShopifyGid(shopifyId) ? viewShopifyId(shopifyId) : shopifyId,
          })),
          ...(selected ?? []).map(value => ({
            label: value,
            value,
          })),
        ],
        "value"
      );
    }
    return autoCompleteSubscriptionIds.filter(Boolean).map(shopifyId => ({
      label: hasShopifyGid(shopifyId) ? viewShopifyId(shopifyId) : shopifyId,
      value: hasShopifyGid(shopifyId) ? viewShopifyId(shopifyId) : shopifyId,
    }));
  }, [autoCompleteSubscriptionIds]);

  const onShopifyIdChange = useCallback(
    debounce(async (shopifyId: string) => {
      setHasResults(true);

      if (!shopifyId) {
        setAutoCompleteSubscriptionIds([]);
        return;
      }

      setLoading(true);
      const res = await typedFrontendVendorApi.getReq("/purchase-state/shopify-ids", {
        query: {
          shopifyId,
        },
      });

      if (res.type === "success") {
        const results = res.body;

        setAutoCompleteSubscriptionIds(results);
      }

      setLoading(false);
    }, 250),
    []
  );

  return (
    <React.Fragment>
      <AutoCompleteWithTextField
        allowMultiple
        selected={selected}
        onSelect={values => handleSubscriptionIdChange(values)}
        onChange={onShopifyIdChange}
        options={options}
        loading={isLoading}
        textFieldProps={textFieldProps}
        willLoadMoreResults
      />
      {!hasResults && <p>Seems like subscription with provided shopifyId doesn{"'"}t exist in Smartrr</p>}
    </React.Fragment>
  );
}

export const Quantity: React.FC<{ amount: number; children?: React.ReactNode }> = ({ children, amount }) => {
  return (
    <div style={{ position: "relative" }}>
      <QuantityBadge>{amount}</QuantityBadge>
      {children}
    </div>
  );
};

export const ItemsPopover: React.FC<{
  itemIndex: number;
  activatorTitle: string;
  stLineItems: (IPurchaseStateLineItem | ISmartrrOrderLineItem)[];
}> = ({ activatorTitle, itemIndex, stLineItems }) => {
  const variantToPurchasablesMap = useVendorPortalVariantToPurchasableMap();
  const [popoverActiveIndex, setPopoverActiveIndex] = useState<number | undefined>();

  return (
    <Popover
      active={popoverActiveIndex === itemIndex}
      activator={
        <span
          onMouseEnter={() => setPopoverActiveIndex(itemIndex)}
          onMouseLeave={() => setPopoverActiveIndex(undefined)}
        >
          {activatorTitle}
        </span>
      }
      autofocusTarget="first-node"
      onClose={() => setPopoverActiveIndex(undefined)}
    >
      <LegacyCard sectioned>
        {stLineItems.map(item => {
          const purchasable = item.vnt?.id ? variantToPurchasablesMap[item.vnt?.id] : undefined;
          const vnt = purchasable ? purchasable.vnts?.find(vnt => vnt.id === item.vnt?.id) : item.vnt;

          const imageUrl = vnt?.purchasableVariantImages?.[0] || (vnt?.id && purchasable?.purchasableImages?.[0]);

          return (
            <div
              key={item.id}
              onMouseLeave={() => setPopoverActiveIndex(undefined)}
              onMouseEnter={() => setPopoverActiveIndex(itemIndex)}
            >
              <LegacyStack spacing="loose" alignment="center">
                {!!imageUrl && (
                  <Quantity amount={item.quantity}>
                    <Thumbnail size="small" source={imageUrl} alt="item" />
                  </Quantity>
                )}
                <LegacyStack vertical spacing="extraTight" distribution="center">
                  <Text variant="bodyMd" as="span" color="success">
                    {purchasable?.purchasableName}
                  </Text>
                  <Text variant="bodyMd" as="span" color="subdued">
                    {vnt?.purchasableVariantName === "Default Title"
                      ? ""
                      : (vnt?.purchasableVariantName ?? "---")}
                  </Text>
                </LegacyStack>
              </LegacyStack>
            </div>
          );
        })}
      </LegacyCard>
    </Popover>
  );
};

export const ProductsAutoComplete = ({
  options,
  selected,
  onSelect,
  onChange,
  textFieldProps,
  ...props
}: ItemsAutocompleteProps): JSX.Element => {
  const [searchText, setSearchText] = useState("");

  return (
    <Autocomplete
      {...props}
      options={options.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()))}
      textField={
        <Autocomplete.TextField
          autoComplete="on"
          {...textFieldProps}
          onChange={value => {
            setSearchText(value);
          }}
          label=""
          value={searchText}
          prefix={<Icon source={SearchMinor} color="highlight" />}
          placeholder={"Search..."}
        />
      }
      onSelect={value => onSelect?.(value)}
      selected={selected}
      allowMultiple
    />
  );
};
export const getFormattedUpcomingDate = (
  purchaseState: IPurchaseStateWithCustomerRelationship | undefined,
  activeOrg: IOrganization | null
) => {
  if (!activeOrg || !purchaseState) {
    return "——";
  }

  const { purchaseStateStatus, subProperties, nextOrderDate, unpauseDate } = purchaseState;

  const nextOrderDateOrUnpauseDate = unpauseDate ?? nextOrderDate;

  if (
    purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused &&
    subProperties?.billingFailedMessage
  ) {
    return getNoUpcomingDateDisclaimer(subProperties?.billingFailedMessage);
  }

  if (
    purchaseState.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Active &&
    purchaseState.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Paused
  ) {
    return (
      <Text variant="bodyMd" as="span" color="subdued">
        {capitalize(purchaseState.purchaseStateStatus)}
      </Text>
    );
  }

  if (!nextOrderDateOrUnpauseDate) {
    return "——";
  }

  const upcomingDate = ISODateString.fromString(nextOrderDateOrUnpauseDate).setZone(activeOrg.billingTimezone);

  const dateStr = (isTooltip: boolean) =>
    `${upcomingDate.toLocaleString({
      day: "2-digit",
      month: "short",
      year: "numeric",
    })} at ${upcomingDate.toLocaleString({
      hour: "numeric",
      hour12: true,
      timeZoneName: isTooltip ? "shortGeneric" : undefined,
    })}`;

  const delta = getDateDelta(upcomingDate, activeOrg);

  return (
    <Tooltip content={<span>{dateStr(true)}</span>}>
      <span>
        {dateStr(false)}&nbsp;
        <Badge status={delta === 1 ? "info" : undefined}>{`in ${pluralize(delta, "day")}`}</Badge>
      </span>
    </Tooltip>
  );
};

const getNoUpcomingDateDisclaimer = (message: string | undefined) => {
  return (
    <NoUpcomingDateContainer>
      <Icon source={RiskMajor} color="warning" />
      <Text variant="bodyMd" as="span" color="subdued">
        {getFormattedWarningText(message)}
      </Text>
    </NoUpcomingDateContainer>
  );
};

export const getFormattedUpcomingDateForSubDetails = (
  purchaseState: IPurchaseStateWithCustomerRelationship | undefined,
  activeOrg: IOrganization | null,
  disabledDays: Date[]
) => {
  if (
    !activeOrg ||
    !purchaseState ||
    purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Cancelled ||
    purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Expired
  ) {
    return "——";
  }

  const { nextOrderDate, subProperties, purchaseStateStatus, unpauseDate } = purchaseState;

  const nextOrderDateOrUnpauseDate = unpauseDate ?? nextOrderDate;

  if (nextOrderDateOrUnpauseDate === null) {
    return "——";
  }

  const updatedDateByBillingDays = updateDateRecur(disabledDays, nextOrderDateOrUnpauseDate);

  if (
    purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused &&
    subProperties?.billingFailedMessage
  ) {
    return getNoUpcomingDateDisclaimer(subProperties?.billingFailedMessage);
  }

  if (updatedDateByBillingDays === null) {
    return "——";
  }

  const upcomingDate = ISODateString.fromString(updatedDateByBillingDays).setZone(activeOrg.billingTimezone, {
    keepLocalTime: true,
  });

  const dateStr = upcomingDate.toLocaleString({
    month: "long",
    day: "2-digit",
    year: "numeric",
  });

  const delta = getDateDelta(upcomingDate, activeOrg);

  return (
    <span>
      {dateStr} <Badge status={delta === 1 ? "info" : undefined}>{`in ${pluralize(delta, "day")}`}</Badge>
    </span>
  );
};

export const getDateDelta = (date: ISODateString, activeOrg: IOrganization) => {
  return Math.ceil(date.diff(nowUTCLuxon().setZone(activeOrg.billingTimezone), "days").days);
};

export const getCPSStatusBadge = (cpsStatus: `${PurchaseStateStatus}` | PurchaseStateStatus) => {
  if (cpsStatus === "FAILED") {
    return (
      <FailedBadgeContainer>
        <Badge>{capitalize(cpsStatus.toLowerCase())}</Badge>
      </FailedBadgeContainer>
    );
  }

  let bagdeStatus: "info" | "success" | "attention" | "warning" | "critical" | "new" | undefined;
  switch (cpsStatus) {
    case "ACTIVE": {
      bagdeStatus = "success";
      break;
    }
    case "CANCELLED": {
      bagdeStatus = "critical";
      break;
    }
    case "PAUSED": {
      bagdeStatus = "warning";
      break;
    }
    default: {
      bagdeStatus = undefined;
      break;
    }
  }
  const badgeText = cpsStatus === "CANCELLED" ? "CANCELED" : cpsStatus.toLowerCase();
  return <Badge status={bagdeStatus}>{capitalize(badgeText)}</Badge>;
};

export const ValueSelectorForSubscriptionsFilter = ({
  field,
  value,
  purchasables,
  sellingPlanGroups,
  setValue,
  currencyCode = "USD",
}: {
  field: string;
  value: string | string[] | undefined;
  setValue: (value: string | string[] | undefined) => void;
  purchasables: IPurchasable[];
  sellingPlanGroups: ISmartrrSellingPlanGroup[];
  currencyCode: CurrencyCode | undefined;
}) => {
  const [{ month, year }, setDate] = useState({ month: new Date().getMonth(), year: new Date().getFullYear() });
  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  switch (field) {
    case "shopifyId": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <SubscriptionIdAutocomplete
            selected={Array.isArray(value) ? value : value ? [value] : []}
            handleSubscriptionIdChange={setValue}
            textFieldProps={{
              placeholder: "Search...",
            }}
          />
        </LegacyStack>
      );
    }
    case "upcomingOrderDate":
    case "createdDate": {
      return (
        <DatePickerContainer>
          <DatePicker
            allowRange
            multiMonth
            month={month}
            year={year}
            onChange={({ start, end }) => {
              const filter = [getTimeFromDate(start).toString()];
              if (end) {
                filter.push(getTimeFromDate(end).toString());
              }

              setValue(filter);
            }}
            onMonthChange={handleMonthChange}
            selected={getSelectedDatesFromFilter(value)}
          />
        </DatePickerContainer>
      );
    }
    case "email":
    case "emailOrName": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <CustomerAutocomplete
            selected={Array.isArray(value) ? value : value ? [value] : []}
            handleCustomerChange={setValue}
            textFieldProps={{
              placeholder: "Search...",
            }}
          />
        </LegacyStack>
      );
    }
    case "status": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <ChoiceList
            title=""
            titleHidden
            allowMultiple
            selected={Array.isArray(value) ? value : value ? [value] : []}
            choices={[
              { label: "Active", value: SubscriptionContractSubscriptionStatus.Active },
              { label: "Paused", value: SubscriptionContractSubscriptionStatus.Paused },
              { label: "Canceled", value: SubscriptionContractSubscriptionStatus.Cancelled },
              { label: "Expired", value: SubscriptionContractSubscriptionStatus.Expired },
            ]}
            onChange={value => setValue(value)}
          />
        </LegacyStack>
      );
    }
    case "estimatedTotal": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <TextField
            autoComplete="off"
            label=""
            labelHidden
            prefix={getSymbolFromCurrency(currencyCode)}
            min={0}
            value={Array.isArray(value) ? value.join(";") : value}
            onChange={value => {
              if (value && new RegExp(/^\d*\.?\d*$/).test(value) && +value > 0) {
                setValue(value);
                return;
              }
              setValue("");
            }}
          />
        </LegacyStack>
      );
    }
    case "items": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <ProductsAutoComplete
            allowMultiple
            selected={(value as string[]) ?? []}
            options={getVariantOptions(purchasables)}
            textFieldProps={{
              label: Array.isArray(value) ? value.join(";") : value,
              value: Array.isArray(value) ? value.join(";") : value,
            }}
            onChange={value => setValue(value)}
            onSelect={value => setValue(value)}
          />
        </LegacyStack>
      );
    }
    case "sellingPlanId": {
      return (
        <LegacyStack vertical spacing="extraTight">
          <ChoiceList
            title=""
            titleHidden
            allowMultiple
            selected={Array.isArray(value) ? value : value ? [value] : []}
            choices={getSellingPlanOptions(sellingPlanGroups)}
            onChange={value => setValue(value)}
          />
        </LegacyStack>
      );
    }
    default: {
      return (
        <LegacyStack vertical spacing="extraTight">
          <Text variant="bodyMd" as="span">
            Select a filter
          </Text>
        </LegacyStack>
      );
    }
  }
};

export const getUpcomingTransactionDateAsString = (
  purchaseState: IPurchaseStateWithCustomerRelationship | undefined,
  activeOrg: IOrganization | null,
  disabledDays: Date[]
) => {
  const unpauseDate: string = purchaseState?.unpauseDate;

  if (!activeOrg || !purchaseState) {
    return "——";
  }

  // Cancelled & Expired subs
  if (
    purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Cancelled ||
    purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Expired
  ) {
    return "——";
  }

  // Paused subs and accounting for unpauseDate
  if (
    (purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused && !unpauseDate) ||
    (!purchaseState.nextBillingDate && !unpauseDate)
  ) {
    return "——";
  }

  const isPrepaid = isCPSPrepaid(purchaseState.schedule);

  if (isPrepaid && purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused) {
    return "——";
  }

  const { nextBillingDate: nextBillingDateStr } = purchaseState;

  const nextBillingOrUnpauseDate = new Date(unpauseDate ?? nextBillingDateStr);

  const dateToDisplay: Date = nextBillingOrUnpauseDate;

  const updatedNextBillingDateByBillingDays = updateDateRecur(disabledDays, dateToDisplay);

  const formattedDate = DateTime.fromJSDate(updatedNextBillingDateByBillingDays).setZone(
    activeOrg.billingTimezone,
    { keepLocalTime: true }
  );

  return formattedDate.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const parseSubscriptionForIndexTable = (
  purchaseStates: (IPurchaseStateWithCustomerRelationship & { cancellationReason?: string | undefined })[],
  sellingPlanGroups: ISmartrrSellingPlanGroup[],
  activeOrg: IOrganization | null
) => {
  return purchaseStates.map((purchaseState, index) => {
    const {
      initialSubmissionDate,
      custRel,
      purchaseStateStatus,
      shopifyId,
      stLineItems,
      sellingPlanId,
      nextBillingDate,
      subProperties,
    } = purchaseState;
    const createdDateWithZone = ISODateString.fromString(initialSubmissionDate).setZone(
      activeOrg?.billingTimezone
    );

    const rruleManager =
      purchaseState?.schedule && activeOrg
        ? RRuleManager.fromJson(purchaseState.schedule, OrgUtils.getBillingSchedule(activeOrg))
        : undefined;

    const nextDelivery = getNextDelivery(purchaseState, activeOrg);

    const billingDate = ISODateString.fromString(nextBillingDate);

    const billingDateStr = `${billingDate.toLocaleString({
      day: "2-digit",
      month: "short",
    })} at ${billingDate.toLocaleString({
      hour: "numeric",
      hour12: true,
    })}`;

    return {
      id: String(purchaseState.id),
      shopifyId: (
        <IdContainer onClick={() => openSubscriptionDetails(purchaseState)}>
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            #{viewShopifyId(shopifyId)}
          </Text>
        </IdContainer>
      ),
      upcomingOrderDate: getFormattedUpcomingDate(purchaseState, activeOrg),
      emailOrName: `${custRel?.firstName} ${custRel?.lastName}`,
      email: custRel?.email,
      estimatedTotal: getTotalDisplay(
        nextDelivery?.paymentMultipleDueOnDate ?? 1,
        purchaseState,
        nextDelivery?.isSkipped === true
          ? rruleManager?.nextActualDelivery?.indexFromScheduleStart || 0
          : nextDelivery?.indexFromScheduleStart || 0,
        true,
        nextDelivery?.indexFromNext
      ),
      items: (
        <ItemsPopover
          stLineItems={purchaseState.stLineItems.filter(
            item =>
              (item.isAddOn || item.isRedeemed ? item.skdIdx === nextDelivery?.indexFromScheduleStart : true) &&
              !item.isRedeemed
          )}
          activatorTitle={pluralize(
            stLineItems
              .filter(
                item =>
                  (item.isAddOn || item.isRedeemed
                    ? item.skdIdx === nextDelivery?.indexFromScheduleStart
                    : true) && !item.isRedeemed
              )
              .reduce((acc, prev) => (acc += prev.quantity), 0),
            "item"
          )}
          itemIndex={index}
        />
      ),
      status: getCPSStatusBadge(
        purchaseState.subProperties?.billingFailedMessage && purchaseStateStatus === "PAUSED"
          ? "FAILED"
          : purchaseStateStatus
      ),
      createdDate: createdDateWithZone
        ? createdDateWithZone.toLocaleString({
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
        : "---",
      sellingPlanId:
        flatten(sellingPlanGroups.map(spg => spg.sellingPlans)).find(sp => sp.shopifyId === sellingPlanId)
          ?.name ?? "---",
      cancellationReason: purchaseState.cancellationReason ?? "No Reason Selected",
      retryCount: subProperties?.billingFailedNotificationCount ?? "---",
      failureReason: subProperties?.billingFailedMessage ?? "---",
      nextRetry: nextBillingDate ? billingDateStr : "---",
    };
  });
};

export const parseUpcomingOrdersForIndexTable = (
  orders: IScheduledEventWithProjectedTotal[],
  onUnskip: (date: Date) => void,
  activeOrg?: IOrganization | null
) => {
  return orders.map((order, index) => {
    const isPrepaid =
      isCPSPrepaid(order.purchaseState.schedule) && isUpcomingOrderCPSPrepaid(order.paymentMultipleDueOnDate);
    const isRecentlyMigratedPrepaid = isCPSRecentlyMigratedPrepaid(order.purchaseState);

    const upcomingOrderLineItems = order.purchaseState.stLineItems.filter(
      item => isNil(item.skdIdx) || item.skdIdx === order.indexFromScheduleStart
    );

    return {
      id: String(order.indexFromNext),
      transactionDate: getUpcomingTransactionDateForOrder(order.date, order.isSkipped, activeOrg),
      totalEstimatedNet: (
        <LegacyStack>
          {order.isSkipped ? (
            <Button
              plain
              removeUnderline
              disabled={isRecentlyMigratedPrepaid}
              onClick={() => onUnskip(order.date)}
            >
              Unskip
            </Button>
          ) : (
            <Text variant="bodyMd" as="span" color="subdued">
              {isPrepaid ? "Prepaid" : order.totalForScheduleIdx}
            </Text>
          )}
        </LegacyStack>
      ),
      items: (
        <div data-testid="smartrr-upcoming-orders-items">
          <ItemsPopover
            stLineItems={upcomingOrderLineItems}
            activatorTitle={pluralize(
              upcomingOrderLineItems.reduce((acc, prev) => (acc += prev.quantity), 0),
              "item"
            )}
            itemIndex={index}
          />
        </div>
      ),
      address:
        getDeliveryAddress(order.purchaseState.deliveryMethod, order.purchaseState.shippingAddress) ?? "--",
      deliveryMethod: getDeliveryMethodTitle(order.purchaseState.deliveryMethod) || "--",
    };
  });
};

export const getUpcomingTransactionDateForOrder = (
  date: Date,
  isSkipped: boolean,
  activeOrg?: IOrganization | null
) => {
  if (!date || !activeOrg) {
    return;
  }

  const luxonDate = toUTCDateLuxon(date).setZone(activeOrg.billingTimezone);

  const dateStr = luxonDate.toLocaleString({
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  const delta = Math.ceil(luxonDate.diff(nowUTCLuxon().setZone(activeOrg.billingTimezone), "days").days);

  if (isSkipped) {
    return (
      <span>
        {dateStr} <Badge status={"new"}>Skipped</Badge>
      </span>
    );
  }

  return (
    <span>
      {dateStr} <Badge status={delta === 1 ? "info" : undefined}>{`in ${pluralize(delta, "day")}`}</Badge>
    </span>
  );
};
